import React from 'react';
import { useState } from 'react';

function App() {
  const [total, setTotal] = useState('');
  const [oweK, setOweK] = useState('');
  const [oweM, setOweM] = useState('');
  const [oweW, setOweW] = useState('');

  return (
    <div>
      <h1>Total Cost</h1>
        <input type="number" placeholder="0" value={total} onChange={(e) => setTotal(+e.target.value)}/>
        
      <div>
        <h1>Individual Items</h1>
        <form>
          <label>Kenenth</label>
          <input type="number" placeholder="0" value={oweK} onChange={(e) => setOweK(+e.target.value)}/>
          <br/>
          <label>Maddy</label>
          <input type="number" placeholder="0" value={oweM} onChange={(e) => setOweM(+e.target.value)}/>
          <br/>
          <label>Will</label>
          <input type="number" placeholder="0" value={oweW} onChange={(e) => setOweW(+e.target.value)}/>
        </form>
      </div>
      <div>
        <h1>Owe</h1>
        Kenneth: {oweK + (total - oweK - oweM - oweW)/3}<br/>
        Maddy: {oweM + (total - oweK - oweM - oweW)/3}<br/>
        Will: {oweW + (total - oweK - oweM - oweW)/3}
      </div>
    </div>
  );
}

export default App;
